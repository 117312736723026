import clsx from "clsx";
import { useStoreState } from "easy-peasy";
import React from "react";

import { PageHeader } from "@transfr-inc/dashboard-components/layouts";

import { BackBreadcrumb } from "../../../components/common/routing/breadcrumb-back";

import "./dashboard.header.scss";

export function DashboardHeader({
  title,
  subtitle,
  toolbar,
  breadcrumbLabel,
  className,
  children,
}) {
  const { ceEnabled } = useStoreState((store) => store.app);
  const breadcrumb = breadcrumbLabel && (
    <BackBreadcrumb label={breadcrumbLabel} />
  );

  return (
    <div className={clsx("dashboard-header", className, ceEnabled && "ce")}>
      <PageHeader title={title} subTitle={subtitle} breadcrumb={breadcrumb}>
        {children}
        {toolbar}
      </PageHeader>
    </div>
  );
}
