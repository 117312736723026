import React, { useEffect, useState } from "react";
import clsx from "clsx";

import {
  Modal,
  ModalBody,
  ModalHeader,
  Slider,
  Slide,
  useSlider,
  Breadcrumb,
  Loader,
} from "@transfr-inc/dashboard-components";

import { LeaderboardSummary } from "./leaderboard.summary";
import { LeaderboardCareers } from "./leaderboard.careers";
import { CareerSummary } from "./career.summary";
import { CareerIcon } from "../../career-icon/career.icon";
import { CareerExplorers } from "./career.explorers";

import "./career-leaderboard.modal.scss";

const LeaderboardTitle = "Career Leaderboard";
const CareerTitle = "Career:";

const sortByRatingFn = (a, b) => {
  const aName = a.lastName.toLowerCase();
  const bName = b.lastName.toLowerCase();
  const aRating = a.rating ?? 0;
  const bRating = b.rating ?? 0;
  const ratingDiff = bRating - aRating;
  if (ratingDiff == 0) {
    return aName < bName ? -1 : 1;
  } else {
    return ratingDiff;
  }
};

export default ({
  open,
  onCancel,
  topCareers,
  activeExplorers,
  customExplorersFn,
  customExplorersAsyncFn,
  classroom,
  career,
  breadcrumbLabel,
  backdropClassName,
  modalClassName,
}) => {
  const totalSlides = 2;
  const slider = useSlider({ totalSlides });
  const [selectedCareer, setSelectedCareer] = useState();
  const [explorers, setExplorers] = useState();
  const [modalTitle, setModalTitle] = useState(LeaderboardTitle);
  const [loading, setLoading] = useState();

  const onCareerSelected = async (career) => {
    const explorersData = await explorersHandler(career);

    setSelectedCareer(career);
    setExplorers(explorersData);

    setModalTitle(`${CareerTitle} ${career.occupationName}`);
    slider.nextSlide();
  };

  const explorersHandler = async (career) => {
    let data;

    if (customExplorersFn) {
      data = customExplorersFn(career);
    } else if (customExplorersAsyncFn) {
      setLoading(true);
      data = await customExplorersAsyncFn(career).finally(() => setLoading());
    }

    return data?.filter((d) => d.rating >= 0).sort(sortByRatingFn);
  };

  const goLeaderboardSlide = () => {
    setSelectedCareer();
    setExplorers();
    setModalTitle(LeaderboardTitle);
    slider.previousSlide();
  };

  useEffect(() => {
    if (open) {
      if (!career) {
        goLeaderboardSlide();
      } else {
        slider.nextSlide();
        onCareerSelected(career);
      }
    }
  }, [open]);

  const modalTitleElement = (
    <>
      {selectedCareer && (
        <Breadcrumb onClick={goLeaderboardSlide} label="View All" />
      )}
      <div className="title-container">
        {selectedCareer && (
          <CareerIcon inline clusterCode={selectedCareer.clusterCode} />
        )}
        <h2>{modalTitle}</h2>
      </div>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={onCancel}
      backdropClassName={backdropClassName}
      modalClassName={clsx("leaderboard-modal", modalClassName)}
    >
      <ModalHeader title={modalTitleElement}></ModalHeader>
      <ModalBody>
        <Slider
          className="leaderboard-modal-body"
          totalSlides={totalSlides}
          currentSlideIndex={slider.currentIndex}
        >
          <Slide>
            {loading && <Loader overlay />}
            <LeaderboardSummary activeExplorers={activeExplorers} />
            <LeaderboardCareers
              topCareers={topCareers}
              onCareerSelected={onCareerSelected}
            />
          </Slide>
          <Slide>
            {loading && <Loader overlay />}
            <CareerSummary
              activeExplorers={activeExplorers}
              career={selectedCareer}
            />
            <CareerExplorers
              explorers={explorers}
              classroom={classroom}
              breadcrumbLabel={breadcrumbLabel}
            />
          </Slide>
        </Slider>
      </ModalBody>
    </Modal>
  );
};
