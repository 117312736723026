import clsx from "clsx";
import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "@transfr-inc/dashboard-components";
import { ChartFilter } from "../../../components/custom/forms";
import { Product, TransfrFeedbackForm } from "../../../models";
import { CeDataboxes } from "./ce.databoxes";
import { CeTopCareers } from "./ce.top-careers";
import { DashboardChart } from "./dashboard.chart";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./dashboard.insights.scss";

export function CeInsights({
  userInfo,
  orgCode,
  generalStats,
  dailyStats,
  onApplyFilters,
  topCareers,
  classroomFilterEnabled,
  organizationFilterEnabled,
  className,
  onExportData,
  exportDataDisabled,
  customExplorersFn,
  classroom,
}) {
  const [categorySelected, setCategorySelected] = useState();
  const { lastDateFilter } = useStoreState((store) => store.date);
  const [dateFilter, setDateFilter] = useState(lastDateFilter);
  const [allTimeStartDate, setAllTimeStartDate] = useState();
  const [truncatedDailyStats, setTruncatedDailyStats] = useState(dailyStats);

  useEffect(() => {
    setTruncatedDailyStats(dailyStats);
    if (!dailyStats.isLoading && dateFilter > 365 && dailyStats.response) {
      let firstDataIndex = dailyStats.response.findIndex(
        (item) => Object.keys(item).length > 1
      );
      firstDataIndex = firstDataIndex == -1 ? 0 : firstDataIndex;
      const truncated = dailyStats.response.slice(firstDataIndex);

      setTruncatedDailyStats({
        ...dailyStats,
        response: truncated,
      });
      setAllTimeStartDate(truncated[0].date);
    }
  }, [dailyStats, dateFilter]);

  const onToggleAllTime = (allTimeSelected) => {
    if (allTimeSelected) {
      setDateFilter(366);
    } else {
      setDateFilter();
      setAllTimeStartDate();
    }
  };

  return (
    <div className={clsx("dashboard-insights", className)}>
      <div className="subheader">
        <ChartFilter
          onApplyFilters={onApplyFilters}
          userInfo={userInfo}
          orgCode={orgCode}
          classroomEnabled={classroomFilterEnabled}
          organizationEnabled={organizationFilterEnabled}
          product={Product.CE}
          loading={truncatedDailyStats.isLoading}
          allTimeStartDate={allTimeStartDate}
          onToggleAllTime={onToggleAllTime}
        />
        <div className="right-hand-buttons">
          <Button size="small">
            <Link href={TransfrFeedbackForm.url} underline={false}>
              Feedback <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Link>
          </Button>
          <Button
            primary
            size="small"
            icon={["fa-light", "file-export"]}
            onClick={onExportData}
            disabled={exportDataDisabled}
          >
            Export Data (.csv)
          </Button>
        </div>
      </div>
      <CeTopCareers
        topCareers={topCareers}
        activeExplorers={generalStats?.activeExplorers}
        breadcrumbLabel={"Dashboard"}
        customExplorersFn={customExplorersFn}
        classroom={classroom}
      ></CeTopCareers>
      <CeDataboxes
        onCategoryChange={setCategorySelected}
        data={generalStats}
      ></CeDataboxes>
      <DashboardChart
        className="chart"
        dailyStats={truncatedDailyStats}
        filterSelected={categorySelected}
      />
    </div>
  );
}
