/* eslint-disable unicorn/no-null */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useStoreState } from "easy-peasy";

import {
  SlashCircleIcon,
  Tile,
  Loader,
} from "@transfr-inc/dashboard-components";
import { Button, ToggleButton } from "@transfr-inc/dashboard-components/forms";
import {
  useLiveClassroomData,
  useUpdateImageStreamEnabled,
  useLiveClassroomImages,
} from "@transfr-inc/dashboard-sockets";
import { formatTime } from "@transfr-inc/dashboard-components/utils";

import container from "../../../../container";
import { useApiRequest } from "../../../../lib/http-client";
import { ShortcutAction } from "../../../../components/shortcuts";
import "./classroom.mission-control.tab.scss";
import {
  checkObjectIsPopulated,
  mapHeadsetStatus,
  mapUserStatus,
} from "../utils/mission-control.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";

export const ClassroomMissionControlTab = ({ classroom, users = [] }) => {
  const socketUrl = process.env.SOCKET_BRIDGE_URL;
  const { currentUser } = useStoreState((store) => store.account);
  const { missionControlEnabled, imageStreamingEnabled } = useStoreState(
    (state) => state.app
  );
  const { token } = currentUser;
  const [initSorting, setInitSorting] = useState();
  const [missionControlDataArray, setMissionControlDataArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const timeoutRef = useRef(null);
  const sortRef = useRef(null);

  const classroomId = classroom?.classroomId;
  const clientCode = classroom?.clientCode;
  const isStreamingEnabled = classroom?.isStreamingEnabled;

  const [showLiveFeed, setShowLiveFeed] = useState(isStreamingEnabled ?? true);

  const { insightsService, classroomService } = container;

  const missionControlClassroom = missionControlEnabled
    ? useLiveClassroomData(socketUrl, token, clientCode, classroomId)
    : {};

  const imageData = imageStreamingEnabled
    ? useLiveClassroomImages(socketUrl, token, clientCode, classroomId)
    : {};

  // Gets mastery for star ratings
  const { response: ceResultsResponse } = useApiRequest(() =>
    insightsService.getClassroomCeResults(classroomId)
  );

  // Convert object map into usable array
  const classroomArray = useMemo(() => {
    const isDataPopulated = checkObjectIsPopulated(missionControlClassroom);
    let data = [];
    if (isDataPopulated) {
      data = Object.entries(missionControlClassroom).map(([userId, info]) => {
        const userInfo = users.find((user) => user.userId === userId) || {};
        const hasUserInfo = checkObjectIsPopulated(userInfo);
        if (hasUserInfo) {
          const matchingLearningExperienceResults = ceResultsResponse
            ? ceResultsResponse.get(info.learningExperienceId)
            : {};
          const studentResults = matchingLearningExperienceResults
            ? matchingLearningExperienceResults.get(userId)
            : {};

          return {
            userInfo,
            userId,
            studentResults,
            ...info,
          };
        }
        return;
      });
    }

    return data.filter((e) => e);
  }, [missionControlClassroom, ceResultsResponse]);

  const zeroState = (
    <>
      {loading ? (
        <Loader overlay></Loader>
      ) : (
        <ShortcutAction className="no-data-message" disabled>
          <SlashCircleIcon></SlashCircleIcon>
          {
            "No users to display, please wait until there are live users in the classroom"
          }
        </ShortcutAction>
      )}
    </>
  );

  const { isConnected, sendControlMessage } = useUpdateImageStreamEnabled(
    socketUrl,
    token,
    clientCode,
    classroomId
  );

  const updateLiveFeed = (newShowLiveFeed) => {
    classroomService.updateClassroomIsStreamingEnabled(
      classroomId,
      newShowLiveFeed
    );
  };

  const onFullScreenHandler = () => {
    console.log("Full Screen Button Clicked!");
  };

  const onToggleLiveFeed = () => {
    setLoading(true);
    setShowLiveFeed((prevShowLiveFeed) => {
      const newShowLiveFeed = !prevShowLiveFeed;
      sendControlMessage({ isStreamingEnabled: newShowLiveFeed });
      updateLiveFeed(newShowLiveFeed);
      return newShowLiveFeed;
    });
    setLoading();
  };

  useEffect(() => {
    return () => {
      setInitSorting();
      setMissionControlDataArray([]);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (sortRef.current) sortRef.current = null;
    };
  }, []);

  useEffect(() => {
    // Update the sorting ref whenever classroomArray changes
    sortRef.current = classroomArray;
  }, [classroomArray]);

  const sortData = () => {
    const sorted = sortRef.current.sort((a, b) => {
      const lastNameA = a?.userInfo?.lastName || "";
      const lastNameB = b?.userInfo?.lastName || "";
      return lastNameA.localeCompare(lastNameB);
    });

    setMissionControlDataArray(sorted);
    setLoading(false);
  };

  useEffect(() => {
    if (classroomArray?.length) {
      if (!initSorting) {
        setLoading(true);
        setInitSorting(true);

        if (timeoutRef.current) clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => {
          sortData();
        }, 5000);
      } else if (missionControlDataArray.length > 0) {
        setMissionControlDataArray((prevData) => {
          // Update existing items
          const updatedData = prevData.map((existingItem) => {
            const matchingItem = classroomArray.find(
              (item) => item.userId === existingItem.userId
            );
            return matchingItem
              ? { ...existingItem, ...matchingItem }
              : existingItem;
          });

          // Add new items
          const newItems = classroomArray.filter(
            (item) =>
              !prevData.some(
                (existingItem) => existingItem.userId === item.userId
              )
          );

          // Combine updated and new items
          return [...updatedData, ...newItems];
        });

        setLoading(false); // Ensure loading is set correctly
      }
    }
  }, [
    initSorting,
    JSON.stringify(classroomArray),
    JSON.stringify(missionControlDataArray),
    JSON.stringify(imageData),
  ]);

  const showTiles = missionControlDataArray?.length > 0;

  return (
    <>
      <div className="mission-control-header">
        <Button onClick={onFullScreenHandler} disabled={!showTiles}>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> Full Screen
        </Button>
        <div className="toggle-live-feed">
          <div className="description">Live Feed</div>
          <ToggleButton
            onChange={onToggleLiveFeed}
            value={showLiveFeed}
            disabled={!isConnected || loading}
          />
        </div>
      </div>
      <div className="mission-control-main-container">
        {!showTiles ? (
          zeroState
        ) : (
          <div className="tile-container">
            {missionControlDataArray.map((headsetInfo, i) => {
              const userInfo = headsetInfo.userInfo;
              const simStatus = mapHeadsetStatus(headsetInfo);
              const userStatus = mapUserStatus(headsetInfo);
              const showIdle = headsetInfo.isIdle && headsetInfo.isActive;
              const matchingLearningExperienceResults = ceResultsResponse.get(
                headsetInfo.learningExperienceId
              );
              const studentResults = matchingLearningExperienceResults
                ? matchingLearningExperienceResults.get(headsetInfo.userId)
                : {};

              return (
                <Tile
                  key={i + headsetInfo.dateTime}
                  firstName={userInfo?.firstName}
                  lastName={userInfo?.lastName}
                  completionPercentage={headsetInfo.simCompletionPercentage}
                  status={simStatus}
                  simName={headsetInfo.simDisplayName}
                  voiceOverCommand={headsetInfo.mostRecentVoCommand}
                  timeInHeadset={headsetInfo.timeInHeadset}
                  batteryPercentage={headsetInfo.batteryLife}
                  wifiSignal={headsetInfo.wifiStrength}
                  userStatus={userStatus}
                  idleTime={
                    showIdle ? `: ${formatTime(headsetInfo.idleTime)}` : ""
                  }
                  isAnonymous={userInfo?.isAnonymous}
                  rating={studentResults?.completed && studentResults?.rating}
                  imgUrl={imageData[userInfo.userId]}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};
