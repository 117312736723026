/**
 * Base class to represent snowplow events.
 * Provides a common structure for event schema and data.
 */
class Event {
  /**
   * Constructor initializes the event's schema and data payload.
   */
  constructor(schema) {
    this.schema = schema;
    this.data = {};
  }
}

/**
 * Represents a specific type of snowplow event: filtering the dashboard.
 */
export class FilterDashboard extends Event {
  /**
   * Constructor initializes the start and end dates for the filter action.
   * @param {string} startDate - The start date of the filter.
   * @param {string} endDate - The end date of the filter.
   */
  constructor(startDate, endDate) {
    super("iglu:com.transfrvr/filter_dashboard/jsonschema/1-0-0");
    this.data.start_date = startDate;
    this.data.end_date = endDate;
  }
}
