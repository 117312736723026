import React from "react";

import {
  Link,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { useUnloadWarning } from "@transfr-inc/dashboard-components/hooks";
import { formatTimestap } from "@transfr-inc/dashboard-components/utils";

import { ClassroomCeSimulations } from "../components/classroom.ce-simulations";
import { ClassroomCeTopCareers } from "../components/classroom.ce-top-careers";
import { ClassroomSummaryStats } from "../components/classroom.ce.databoxes";

import container from "../../../../container";
import { useClassroomCEProgress } from "../../../../lib/classroom-progress.hook";
import { useApiRequest } from "../../../../lib/http-client";

import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TransfrFeedbackForm } from "../../../../models";
import "./classroom.progress.ce.tab.scss";

export function ClassroomProgressCeTab({ classroom, explorers, disabled }) {
  const { classroomId, name } = classroom;
  const { insightsService } = container;

  const { careersExplored, careersUnexplored, topCareers, insights } =
    useClassroomCEProgress(classroomId, explorers);

  const exportCeInsightsRequest = () => {
    const { clientCode: orgCode } = classroom;
    const fileName = `${name} - ${formatTimestap()}`;
    return insightsService.exportCeClassroomData(
      classroomId,
      orgCode,
      fileName
    );
  };

  const { loading: downloadingFile, sendRequest: exportInsights } =
    useApiRequest(exportCeInsightsRequest, false);

  useUnloadWarning({ trigger: downloadingFile });

  return (
    <>
      <ClassroomCeTopCareers
        topCareers={topCareers}
        careers={careersExplored}
        insights={insights}
        classroom={classroom}
      />
      <div className="classroom-sims-section">
        <div className="sims-header">
          <h2>Insights</h2>
          <div className="right-hand-buttons">
            <Button size="small">
              <Link href={TransfrFeedbackForm.url} underline={false}>
                Feedback <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </Link>
            </Button>
            <Button
              primary
              size="small"
              icon={["fa-light", "file-export"]}
              onClick={exportInsights}
              disabled={downloadingFile || disabled}
            >
              Export Data (.csv)
            </Button>
          </div>
        </div>
        <ClassroomSummaryStats masteryStats={insights} />
        <div className="notification-loader-container">
          {downloadingFile && (
            <Notification
              type={NotificationType.info}
              closable
              icon={["fa-solid", "spinner"]}
              iconConfig={{ spin: true }}
            >
              We’re preparing a download of <strong>{`${name}'s`}</strong>{" "}
              insights. Please wait...
            </Notification>
          )}
        </div>
      </div>
      <ClassroomCeSimulations
        careers={careersExplored}
        unexplored={careersUnexplored}
      ></ClassroomCeSimulations>
    </>
  );
}
