import clsx from "clsx";
import { saveAs } from "file-saver";
import React, { useMemo, useState } from "react";

import {
  FullScreenModal,
  FullScreenModalBody,
  FullScreenModalHeader,
  Loader,
  Notification,
  NotificationType,
  TextTooltip,
} from "@transfr-inc/dashboard-components";

import {
  FormTable,
  SortDirection,
} from "@transfr-inc/dashboard-components/data-table";

import { getProductName } from "../../../models";

import { Button, FileInput } from "@transfr-inc/dashboard-components/forms";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreState } from "easy-peasy";
import { CancelModal } from "../../../components/modals";
import container from "../../../container";
import { getErrorMessage } from "../../../lib/browser-utils";
import { Product } from "../../../models";
import "./roster-modal.scss";
import {
  DataTableColumnDefinition,
  TEMPLATE_DOWNLOAD_INFO_TEXT,
  cancelBodyText,
  cancelBodyTitle,
  convertToCSV,
} from "./utils";
import { ZeroStateMessage } from "./zero-state-message";

const downloadBulkUploadTemplate = (classroomId, product) => {
  if (classroomId) {
    const productSuffix = getProductName(product)?.toLowerCase();
    saveAs(
      `/templates/bulk-user-upload-template.${productSuffix}.csv`,
      "roster-user-upload-template.csv"
    );
  } else {
    saveAs(
      `/templates/roster-user-upload-template.csv`,
      "roster-user-upload-template.csv"
    );
  }
};

export const RosterModal = ({
  onClose,
  show,
  responsiveStyles,
  classroomId,
  product = Product.TS,
}) => {
  const { classrooms } = useStoreState((store) => store.organization);

  const [loading, setLoading] = useState();
  const [template, setTemplate] = useState();
  const [showCancelModal, setShowCancelModal] = useState();
  const [uploadNotification, setUploadNotification] = useState(false);
  const [rowToAdd, setRowToAdd] = useState();
  const { currentUser } = useStoreState((store) => store.account);

  const { userService } = container;

  const classroomCodes = useMemo(() => {
    let classroomMap = [];
    if (classrooms?.length) {
      classroomMap = classrooms.map((classroom) => ({
        id: classroom.code,
        name: classroom.code,
        description: classroom.name,
      }));
    }
    return classroomMap;
  }, [classrooms]);

  const columns = [
    DataTableColumnDefinition.FIRST_NAME,
    DataTableColumnDefinition.LAST_NAME,
    ...(!classroomId
      ? [DataTableColumnDefinition.CLASSROOM_CODE(classroomCodes)]
      : []),
    DataTableColumnDefinition.USERNAME,
    DataTableColumnDefinition.EMAIL,
    DataTableColumnDefinition.PASSWORD,
  ];

  const onConfirmPublishClick = async ({ formData }) => {
    const mappedData = formData.map((row) => ({
      "First Name": row.firstName,
      "Last Name": row.lastName,
      ...(!classroomId ? { "Classroom Code": row.classroomCode?.id } : {}),
      Username: row.username,
      Email: row.email,
      Password: row.password,
    }));

    const file = convertToCSV(mappedData);

    // eslint-disable-next-line no-undef
    const formDataToUpload = new FormData();
    formDataToUpload.append("file", file, "roster.csv");
    formDataToUpload.append("organization_code", currentUser.organizationCode);
    classroomId && formDataToUpload.append("classroom_id", classroomId);

    setLoading(true);
    try {
      const isValidCsv = await userService.uploadUsersAsync(
        formDataToUpload,
        currentUser.organizationCode,
        undefined,
        true
      );
      if (isValidCsv == true) {
        setUploadNotification({
          message:
            `Your file has been successfully uploaded. Due to its size this file will ` +
            `continue to process in the background. Feel free to continue and we will ` +
            `send you an email when it's finished. `,
          type: NotificationType.success,
        });
        onCancel();
      }
    } catch (error) {
      setUploadNotification({
        message: getErrorMessage(error.data),
        type: NotificationType.error,
      });
    }
    setLoading(false);
  };

  const onHandleFile = async (file) => {
    setLoading(true);
    try {
      const parsedCsv = await userService.validateRosterCsv(
        file,
        currentUser.organizationCode
      );
      if (parsedCsv) {
        const mappedCsv = parsedCsv.map((csv) => {
          if (csv?.classroomCode) {
            csv.classroomCode = {
              id: csv?.classroomCode,
              name: csv?.classroomCode,
              description: csv?.classroomCode,
            };
          }
          return csv;
        });
        setTemplate(mappedCsv);
        setUploadNotification({
          message: "File imported successfully.",
          type: NotificationType.success,
        });
      }
    } catch (error) {
      setUploadNotification({
        message: getErrorMessage(error.data),
        type: NotificationType.error,
      });
    }
    setLoading(false);
  };

  const onCancel = () => {
    setTemplate();
    setShowCancelModal();
    onClose();
  };

  const onAddRow = () => {
    setRowToAdd({
      firstName: "",
      lastName: "",
      classroomCode: "",
      username: "",
      email: "",
      password: "",
    });
  };

  const handleCloseModal = () => {
    // FullScreenModal calls its onClose handler on first render, so we'll only
    // show the cancel modal if the roster modal is actually open.
    if (show) {
      setShowCancelModal(true);
    }
  };

  return (
    <>
      <FullScreenModal
        className="roster-modal"
        onClose={handleCloseModal}
        open={show}
      >
        <FullScreenModalHeader
          title="Roster"
          titleIcon="fa-regular fa-clipboard-list-check"
        />
        <FullScreenModalBody>
          <div className="roster-modal-body">
            {loading && <Loader overlay></Loader>}
            <div>
              <span className="roster-modal-description">
                {`Quickly create trainees ${
                  classroomId
                    ? "for this classroom"
                    : "across your organization"
                }. If a trainee already exists their information will be updated.`}
              </span>
            </div>
            <div className="roster-action-button-row">
              <div className="left-group">
                <Button
                  icon={["fa-light", "download"]}
                  onClick={() =>
                    downloadBulkUploadTemplate(classroomId, product)
                  }
                >
                  Template (.csv)
                </Button>
                <FileInput
                  primary={false}
                  size="small"
                  icon={["fa-regular", "file-import"]}
                  onHandleFile={onHandleFile}
                  className={"main-dialog"}
                  disabled={loading}
                  loader={loading}
                  label={"Import (.csv)"}
                ></FileInput>
                <TextTooltip text={TEMPLATE_DOWNLOAD_INFO_TEXT}>
                  <FontAwesomeIcon icon="fa-regular fa-circle-info" />
                </TextTooltip>
              </div>
              <div className="right-group">
                <Button icon={["fa-regular", "plus-circle"]} onClick={onAddRow}>
                  Add Row
                </Button>
              </div>
            </div>
            <div
              className={clsx(
                "roster-list-container",
                classroomId && "classroom-upload"
              )}
            >
              <FormTable
                data={template}
                columns={columns}
                showValidationStatus
                rowHeightConfig={{ large: 80, medium: 160, small: 280 }}
                virtualRowEnabled={false}
                hasRemoveColumn
                defaultSortPropName={"firstName"}
                defaultSortDirection={SortDirection.DESCENDING}
                onCancel={handleCloseModal}
                handleSubmitForm={onConfirmPublishClick}
                responsiveStyles={responsiveStyles}
                showBottomBarOptions
                zeroStateMessage={<ZeroStateMessage loading={loading} />}
                rowToAdd={rowToAdd}
                setRowToAdd={setRowToAdd}
              />
            </div>
          </div>
        </FullScreenModalBody>
        {uploadNotification && (
          <div className="notifications-area">
            <div className="notification-container">
              <Notification
                type={uploadNotification.type}
                closable
                onClose={() => {
                  setUploadNotification();
                }}
              >
                {uploadNotification.message}
              </Notification>
            </div>
          </div>
        )}
        <CancelModal
          open={showCancelModal}
          onCancel={() => setShowCancelModal()}
          title={cancelBodyTitle}
          body={cancelBodyText}
          cancelText="No"
          acceptText="Yes, Cancel"
          onAccept={onCancel}
        />
      </FullScreenModal>
    </>
  );
};
