import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import { Link, Loader } from "@transfr-inc/dashboard-components";
import {
  Column,
  ColumnOptions,
  ColumnType,
  Table,
  UserCell,
} from "@transfr-inc/dashboard-components/data-table";

import { MasteryCell } from "../../../../components/table-cells";
import { RootPath, UsersRoute } from "../../../../lib/routes";

const getGradebookColumns = (simulations) => {
  return simulations.map(
    (sim) =>
      new Column(
        sim.learningObjectiveId,
        sim.displayName,
        ColumnType.CUSTOM,
        new ColumnOptions({
          customFunction: (value) => (
            <MasteryCell status={value.status} score={value.mastery} />
          ),
        })
      )
  );
};

export const ClassroomGradebookTable = ({
  data: gradebookData,
  simulations,
  classroom,
  maxVisibleSims = 7,
  onSortClick,
  isLoading,
}) => {
  const [gradebookColumns, setGradebookColumns] = useState();

  const UsersColumn = new Column(
    "lastName",
    "Name",
    ColumnType.CUSTOM,
    new ColumnOptions({
      sortingEnabled: true,
      isFixed: true,
      width: 250,
      sortKeyFields: ["isAnonymous", "lastName"],
      customFunction: (_, row) => (
        <UserCell
          lastName={row.lastName}
          firstName={row.firstName}
          isAnonymous={row.isAnonymous}
          userDetailRoute={
            <Link
              routerComponent={NavLink}
              to={{
                pathname: `${RootPath}${UsersRoute.path}/${row.id}`,
                state: {
                  breadcrumbLabel: classroom.name,
                  classroomId: classroom.classroomId,
                  userId: row.userId,
                },
              }}
            >{`${row.lastName}, ${row.firstName}`}</Link>
          }
        />
      ),
    })
  );

  useEffect(() => {
    if (gradebookData && simulations) {
      const cols = getGradebookColumns(simulations);
      setGradebookColumns([UsersColumn, ...cols]);
    }
  }, [gradebookData, simulations]);

  return (
    <>
      {isLoading && <Loader fixed />}
      {gradebookData && gradebookColumns && (
        <Table
          className="gradebook-table"
          columns={gradebookColumns}
          data={gradebookData}
          maxVisibleColumns={maxVisibleSims}
          onSortClick={onSortClick}
          staticHeaders
          keyValue="userId"
          minVisibleRows={10}
        ></Table>
      )}
    </>
  );
};
