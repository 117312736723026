import React, { useState } from "react";
import container from "../../../../container";

import {
  Loader,
  NotificationStack,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { useApiRequest } from "../../../../lib/http-client";

import { Product } from "../../../../models";

import "./classroom.menu-builder.tab.scss";
import { ClassroomTsMenuBuilder } from "../components/menu-builder/layout/ts-menu-builder";
import { ClassroomCeMenuBuilder } from "../components/menu-builder/layout/ce-menu-builder";

export const ClassroomMenuBuilderTab = ({ classroom, enableEdit }) => {
  const { menuBuilderService, insightsService, simulationsService } = container;

  const isTsClassroom = classroom?.products?.includes(Product.TS);
  const isCeClassroom = classroom?.products?.includes(Product.CE);

  const [notifications, setNotifications] = useState([]);

  const onCloseNotification = (indexToRemove) => {
    setNotifications(
      notifications.filter((n, index) => index !== indexToRemove)
    );
  };

  const showSuccessNotification = (message) => {
    showNotification(NotificationType.success, message, "circle-check");
  };

  const showNotification = (type, message, icon) => {
    const notification = { type, message, icon };
    setNotifications((n) => {
      const notificationsUpdated = n ?? [];
      return [...notificationsUpdated, notification];
    });
  };

  return (
    <div className="menu-builder-container">
      {isTsClassroom && (
        <ClassroomTsMenuBuilder classroom={classroom} enableEdit={enableEdit} />
      )}
      {isCeClassroom && (
        <ClassroomCeMenuBuilder classroom={classroom} enableEdit={enableEdit} />
      )}
    </div>
  );
};
