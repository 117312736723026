import React from "react";
import { useHistory } from "react-router-dom";

import { Breadcrumb } from "@transfr-inc/dashboard-components";

export const BackBreadcrumb = ({ label, className }) => {
  const { goBack } = useHistory();

  return <Breadcrumb label={label} classname={className} onClick={goBack} />;
};
