/**
 * Base class to represent snowplow entities.
 * Provides a common structure for event schema and data.
 */
class Entity {
  /**
   * Constructor initializes the entity's schema and data payload.
   */
  constructor(schema) {
    this.schema = schema;
    this.data = {};
  }

  /**
   * Formats the entity as a Snowplow context object (schema + data).
   * Required for adding global context to a tracker as instantiating an
   * object instance does not work.
   * @returns {Object} A context object
   */
  toContext() {
    return {
      schema: this.schema,
      data: this.data,
    };
  }
}

/**
 * Represents a organization snowplow entity.
 */
export class Organization extends Entity {
  /**
   * Constructor initializes the organization code for the entity.
   * @param {string} organizationCode - The code identifying the organization.
   */
  constructor(organizationCode) {
    super("iglu:com.transfrvr/organization/jsonschema/2-0-0");
    this.data.organization_code = organizationCode;
  }
}
