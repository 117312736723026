import { setDailyStats } from "../lib/chart-utils";
import HttpClient from "../lib/http-client";

export default class TrekService extends HttpClient {
  constructor(...args) {
    super(...args);
  }

  getCareerImageUrl(transfrId) {
    return `${process.env.CESE_FE_URL}/images/careers/${transfrId}/questions/1.png`;
  }

  getSkillImage(imageId) {
    return `${process.env.CESE_FE_URL}/images/skills/${imageId}.png`;
  }

  async getCareerPathway(userId) {
    return await this.http.get(
      `/users/${userId}/career-plan?include_lightcast_data=true`
    );
  }

  async getUsersExportData({ classroomId, userId, options }) {
    const defaultOptions = {
      include_explored_careers: true,
      include_selected_career: true,
      include_career_goals: true,
      include_favorited_careers: true,
      include_lightcast_data: true,
    };
    return await this.http.post(`exports/users`, {
      classroom_id: classroomId,
      user_id: userId,
      options: { ...defaultOptions, ...options },
    });
  }

  async getInsights({ classroomId, orgCode, filters, daily }) {
    const { dateRange } = filters ?? {};

    const casedFilters = {};
    if (dateRange) {
      const { startDate: start_date, endDate: end_date } = dateRange;
      casedFilters.date_range = {
        start_date,
        end_date,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    }

    return await this.http
      .post(
        `exports/users/${daily ? "daily-" : ""}summary`,
        {
          classroom_id: classroomId,
          filters: casedFilters,
        },
        {
          headers: {
            organization: orgCode,
          },
        }
      )
      .then((response) =>
        daily ? setDailyStats(filters, response) : response
      );
  }

  async getInsightsGrouped({ classroomId, orgCode, filters }) {
    const { dateRange } = filters ?? {};

    const casedFilters = {};
    if (dateRange) {
      const { startDate: start_date, endDate: end_date } = dateRange;
      casedFilters.date_range = {
        start_date,
        end_date,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    }

    return await this.http.post(
      `exports/users/grouped-summary`,
      {
        classroom_id: classroomId,
        filters: casedFilters,
      },
      {
        headers: {
          organization: orgCode,
        },
      }
    );
  }

  async getTopCareers({ orgCode, classroomId, filters }) {
    const { dateRange } = filters ?? {};

    const casedFilters = {};
    if (dateRange) {
      const { startDate: start_date, endDate: end_date } = dateRange;
      casedFilters.date_range = {
        start_date,
        end_date,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    }

    return await this.http.post(
      "exports/users/top-careers",
      {
        classroom_id: classroomId,
        filters: casedFilters,
      },
      {
        headers: {
          organization: orgCode,
        },
      }
    );
  }

  async getUserJourneyStatus(userId) {
    return await this.http.get(`users/${userId}/journey-status`);
  }

  async getPathwayPDF(userId) {
    return await this.http.get(`reports/user-pathway?user_id=${userId}`, {
      responseType: "blob",
    });
  }
}
