import React, { useState } from "react";
import clsx from "clsx";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Loader,
} from "@transfr-inc/dashboard-components";

import { Button } from "@transfr-inc/dashboard-components/forms";

import "./reset-menu.modal.scss";
import { Product } from "../../../../../../../models";

export const ResetMenuModal = ({
  open,
  onClose,
  onConfirmReset,
  async,
  className,
  checkVersionNumber,
  product,
}) => {
  const [loading, setLoading] = useState();

  const onConfirm = async () => {
    setLoading(true);
    if (product !== Product.CE) {
      const versionNumberIsValid = await checkVersionNumber();
      if (!versionNumberIsValid) {
        setLoading();
        onClose();
        return;
      }
    }
    if (async) {
      onConfirmAsync();
    } else {
      onConfirmReset?.();
    }
  };

  const onConfirmAsync = async () => {
    setLoading(true);

    try {
      await onConfirmReset?.();
    } finally {
      setLoading();
    }
  };

  return (
    <Modal
      modalClassName={clsx("reset-menu-modal", className)}
      open={open}
      onClose={onClose}
      preventClose={loading}
    >
      {loading && <Loader overlay />}
      <ModalHeader
        icon={["fa-regular", "clock-rotate-left"]}
        title="Reset To Default"
      />
      <ModalBody>
        Are you sure that you would like to reset this menu? You will lose any
        menu customizations that have been made. This action cannot be undone.
      </ModalBody>
      <ModalFooter>
        <Button disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        <Button destructive disabled={loading} onClick={onConfirm}>
          Yes, Reset Menu
        </Button>
      </ModalFooter>
    </Modal>
  );
};
