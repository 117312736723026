import clsx from "clsx";
import React, { useMemo, useState } from "react";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

import { ProductDropdownOption } from "./product-dropdown-option";

import { getProduct } from "../../../models/product";

import "./product-dropdown-list.scss";

export function ProductDropDownList({
  placeholder = "Select a Product",
  products,
  className,
  onProductSelected,
  defaultProduct,
  cePlusTrk = false,
  productImages = false,
  clearEnabled,
}) {
  const [selectedProduct, setSelectedProduct] = useState(
    getProduct(defaultProduct)
  );

  const onOptionSelected = (option) => {
    setSelectedProduct(option);
    onProductSelected(option);
  };

  const productOptions = useMemo(() => {
    return products?.map((p) => {
      const product = getProduct(p);

      if (product) {
        return (
          <DropdownItem
            className="product-dropdown-option"
            key={`product-${product.name}`}
            value={product}
          >
            <ProductDropdownOption
              product={product}
              productImagesEnabled={productImages}
              cePlusTrkEnabled={cePlusTrk}
            />
          </DropdownItem>
        );
      }
    });
  }, [products]);

  return (
    <div className={clsx("product-drop-down-list", className)}>
      <Dropdown
        selected={selectedProduct}
        onOptionSelected={onOptionSelected}
        className="product-dropdown"
      >
        <DropdownButton
          placeholder={placeholder}
          required
          clearEnabled={clearEnabled}
        >
          {selectedProduct && (
            <ProductDropdownOption
              product={selectedProduct}
              productImagesEnabled={productImages}
              cePlusTrkEnabled={cePlusTrk}
            />
          )}
        </DropdownButton>
        <DropdownItems minHeight={150}>{productOptions}</DropdownItems>
      </Dropdown>
    </div>
  );
}
