import HttpClient from "../lib/http-client";

const simulationsCache = [];

/**
 * @memberof Services
 */
class SimulationsService extends HttpClient {
  // CE Simulations hardly change so a cache is implemented
  // to save some network requests
  async getAllSimulations() {
    return simulationsCache.length > 0
      ? Promise.resolve(simulationsCache)
      : this.http.get("/simulations").then((response) => {
          simulationsCache.push(...response);
          return simulationsCache;
        });
  }

  async getClassroomSimulations(classroomId) {
    return this.http
      .get(`/classroom/${classroomId}/simulations`)
      .then((response) => response?.simulations);
  }

  async getOrganizationCeSimulations(orgCode) {
    const apiUrl = `/organization/${orgCode}/ce/simulations`;
    return this.http.get(apiUrl).then((response) => response?.simulations);
  }

  async updateClassroomSimulations(classroomId, simulations) {
    return this.http.post(`/classroom/${classroomId}/simulations`, {
      simulation_codes: simulations,
    });
  }
}

export default SimulationsService;
