import clsx from "clsx";
import { useStoreState } from "easy-peasy";
import React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

import {
  Link,
  Loader,
  PlaceholderProducts,
} from "@transfr-inc/dashboard-components";
import {
  Column,
  ColumnOptions,
  ColumnType,
  DataTable,
  SortDirection,
} from "@transfr-inc/dashboard-components/data-table";
import {
  PageHeader,
  PageLayout,
} from "@transfr-inc/dashboard-components/layouts";

import { OrgsRoute, RootPath } from "../../lib/routes";

import "./organization-list.scss";

export function OrganizationList({ responsiveStyles, className }) {
  const { ceEnabled } = useStoreState((store) => store.app);
  const { products } = useStoreState((store) => store.account);
  const { url } = useRouteMatch(`${RootPath}${OrgsRoute.path}`);

  const { organizations, loadingOrganizations } = useStoreState(
    (store) => store.organization
  );

  const columns = [
    new Column(
      "name",
      "Name",
      ColumnType.LINK,
      new ColumnOptions({
        sortingEnabled: true,
        overflowEnabled: true,
        customFunction: (value, row) => (
          <Link
            routerComponent={NavLink}
            to={{
              pathname: `${url}/${row.code}`,
              state: {
                organization: row,
                breadcrumbLabel: "All Organizations",
              },
            }}
          >
            {value}
          </Link>
        ),
      })
    ),
    new Column(
      "totalClassrooms",
      "Classrooms",
      ColumnType.COUNT,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "totalInstructors",
      "Instructors",
      ColumnType.COUNT,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "totalTrainees",
      "Trainees",
      ColumnType.COUNT,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "simsCompleted",
      "Sims Completed",
      ColumnType.COUNT,
      new ColumnOptions({ sortingEnabled: true })
    ),
    new Column(
      "simsMastered",
      "Sims Mastered",
      ColumnType.COUNT,
      new ColumnOptions({ sortingEnabled: true })
    ),
    ...(ceEnabled && products.length > 1
      ? [
          new Column(
            "products",
            "Product",
            ColumnType.CUSTOM,
            new ColumnOptions({
              sortingEnabled: true,
              customFunction: (value) => {
                return (
                  <PlaceholderProducts products={value}></PlaceholderProducts>
                );
              },
            })
          ),
        ]
      : []),
  ];

  const pageHeader = (
    <PageHeader
      title={"Organizations"}
      badge={loadingOrganizations ? "..." : organizations?.length}
    ></PageHeader>
  );

  return (
    <PageLayout
      className={clsx("organizations-list-page", className)}
      header={pageHeader}
      organizationFilterEnabled={false}
      responsiveStyles={responsiveStyles}
    >
      {loadingOrganizations && <Loader fixed />}
      <DataTable
        columns={columns}
        data={organizations}
        className={clsx(
          "organization-list-data-table",
          ceEnabled && products.length > 1 && "multiple-products"
        )}
        keyFields={["code"]}
        defaultSortPropName="name"
        defaultSortDirection={SortDirection.ASCENDING}
        responsiveStyles={responsiveStyles}
        keyPrefix="organization"
        rowHeightConfig={{ large: 54 }}
        virtualRowEnabled
      />
    </PageLayout>
  );
}
