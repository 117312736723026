import clsx from "clsx";
import { useStoreState } from "easy-peasy";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  useButtonBarShift,
  useScreenBreakpoint,
} from "@transfr-inc/dashboard-components/hooks";

import { Role } from "../../models/role";
import { NavbarItemProfile } from "./navbar-item.profile";
import { NavbarRoutes } from "./navbar.routes";

import avatarImage from "../../../public/images/avatar.png";
import transfrLogo from "../../../public/images/logo.svg";

import "./navbar.scss";

import {
  NavBar,
  NavBarMobile,
  NavBarSection,
  useDrawer,
} from "@transfr-inc/dashboard-components";

export function NavigationBar({ onSkipNavigation, isStudentExperience }) {
  const location = useLocation();
  const { role } = useStoreState((store) => store.account);
  const { isMobile } = useScreenBreakpoint();
  const navbarDrawer = useDrawer();
  const navItemsMode = isMobile ? "more" : "full";
  const { toggleShift, revertShift } = useButtonBarShift();

  useEffect(() => {
    toggleShift();
    return () => revertShift();
  }, [navbarDrawer.isExpanded]);

  useEffect(() => {
    if (isMobile && navbarDrawer.isExpanded) {
      navbarDrawer.toggleDrawer();
    }
  }, [location, isMobile]);

  const drawerContent = (
    <>
      <div className="skip-nav">
        <a href="#" onClick={(e) => onSkipNavigation(e)}>
          Skip Navigation
        </a>
      </div>
      <div>
        <img
          className={clsx(
            "logo",
            !navbarDrawer.isExpanded && !isMobile && "wrapped"
          )}
          src={transfrLogo}
          alt="transfr logo"
        ></img>
      </div>
      <div
        className={clsx(
          "items-container",
          !navbarDrawer.isExpanded && !isMobile && "collapsed"
        )}
      >
        <NavbarRoutes
          isExpanded={isMobile ? true : navbarDrawer.isExpanded}
          mode={navItemsMode}
        ></NavbarRoutes>
      </div>
      <NavbarItemProfile
        isExpanded={isMobile ? true : navbarDrawer.isExpanded}
      ></NavbarItemProfile>
    </>
  );

  return !isMobile ? (
    <NavBar
      className={clsx("unified-dashboard-navbar")}
      onSkipNavigation={onSkipNavigation}
      isStudentExperience={isStudentExperience}
    >
      <NavBarSection>
        <NavbarRoutes
          isExpanded={isMobile ? true : navbarDrawer.isExpanded}
          mode={navItemsMode}
        ></NavbarRoutes>
      </NavBarSection>
      <NavBarSection className="bottom-section">
        <NavbarItemProfile
          isExpanded={isMobile ? true : navbarDrawer.isExpanded}
        ></NavbarItemProfile>
      </NavBarSection>
    </NavBar>
  ) : (
    <NavBarMobile
      drawerContent={drawerContent}
      moreImage={avatarImage}
      useMore={role != Role.STUDENT.id}
      isDrawerExpanded={navbarDrawer.isExpanded}
      onDrawerTransitionEnd={navbarDrawer.onTransitionEnd}
      onMoreClick={navbarDrawer.toggleDrawer}
    >
      <NavbarRoutes isExpanded={false} mode="mobile"></NavbarRoutes>
      {role == Role.STUDENT.id && (
        <NavbarItemProfile className={"hide-more"}></NavbarItemProfile>
      )}
    </NavBarMobile>
  );
}
