import React from "react";
import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";

import { SlashCircleIcon, Loader } from "@transfr-inc/dashboard-components";

import "./ce-menu-builder-simulations.scss";
import { ShortcutAction } from "../../../../../../components/shortcuts";
import { ClassroomCeSimulationAccordion } from "../../classroom.ce-simulation.accordion";

const query = {
  "small-panel": {
    minWidth: 320,
    maxWidth: 850,
  },
  "medium-panel": {
    minWidth: 700,
    maxWidth: 900,
  },
  "large-panel": {
    minWidth: 900,
  },
};

export function CeMenuBuilderSimulations({ careers }) {
  const [params, containerRef] = useContainerQuery(query);

  return (
    <div className="classroom-ce-menu-builder-sims-section">
      {careers?.length === 0 && (
        <ShortcutAction className="no-data-message" disabled>
          <SlashCircleIcon></SlashCircleIcon>
          {careers?.length < 1
            ? "There are no careers for this classroom."
            : "No data to display. Please try adjusting your search or filters."}
        </ShortcutAction>
      )}
      <div className="sim-accordions-container" ref={containerRef}>
        {careers ? (
          careers.map((d, i) => (
            <ClassroomCeSimulationAccordion
              key={`${d.id}`}
              sim={d}
              index={i + 1}
              responsiveStyles={params}
              isExpanded={false}
              hideExpandProperties={true}
            />
          ))
        ) : (
          <Loader overlay />
        )}
      </div>
    </div>
  );
}
