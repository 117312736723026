import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useApiRequest } from "../../../lib/http-client";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Loader,
  Badge,
} from "@transfr-inc/dashboard-components";

import { SearchBox, useSearch } from "@transfr-inc/dashboard-components/forms";

import container from "../../../container";
import "./sync-event-details.modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntegrationDate from "../list/components/integration-date";

const SyncEventDetailsModal = ({
  open = false,
  onClose,
  resourceType,
  eventType,
  jobExecutionId,
  typeLabel,
  eventLabel,
  executionDateTime,
}) => {
  const { integrationService } = container;
  const { loading, response } = useApiRequest(() =>
    integrationService.getEventDetails(jobExecutionId, resourceType, eventType)
  );
  const [filteredData, setFilteredData] = useState([]);

  const syncDetailSearch = useSearch({
    data: response,
    searchKeys: ["resourceName", "classroomNames"],
  });

  const onSearchTermChange = (term) => {
    const results = syncDetailSearch.queryData(term);
    setFilteredData(results);
  };

  useEffect(() => {
    const results = syncDetailSearch.updateData(response);
    setFilteredData(results);
  }, [response]);

  const iconMap = {
    student: "fa-head-vr",
    teacher: "fa-chalkboard-teacher",
    classrooms: "fa-screen-users",
  };

  const modalTitle = (
    <>
      <div className="event-log-date">
        Event Log: <IntegrationDate date={executionDateTime} />
      </div>
      <div className="modal-title-container">
        <FontAwesomeIcon icon={`fa-regular ${iconMap[resourceType]}`} />
        <div className="event-modal-title-text">
          {typeLabel} {eventLabel}
        </div>
        {response && <Badge value={response.length} />}
      </div>
    </>
  );

  const eventItems = filteredData
    ? filteredData.map((e, i) => (
        <div
          key={`${e.resourceName}-${i}`}
          className={`event-item ${e.resourceType}`}
        >
          {e.classroomNames && (
            <div className="classroom-names">
              {e.classroomNames.split(",").join(", ")}
            </div>
          )}
          <div className="resource-name">{e.resourceName}</div>
        </div>
      ))
    : [];

  return (
    <Modal
      modalClassName="sync-event-details-modal"
      open={open}
      onClose={onClose}
    >
      <ModalHeader title={modalTitle} />
      <ModalBody>
        {loading && <Loader />}

        {response && (
          <>
            <SearchBox
              disabled={!eventItems}
              placeholder="Search"
              onSearchTermChange={onSearchTermChange}
            />
            <div className="event-list">{eventItems}</div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

SyncEventDetailsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  resourceType: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  jobExecutionId: PropTypes.string.isRequired,
  typeLabel: PropTypes.string.isRequired,
  eventLabel: PropTypes.string.isRequired,
  executionDateTime: PropTypes.string.isRequired,
};

export default SyncEventDetailsModal;
