import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionHeaderName,
  TextTooltip,
} from "@transfr-inc/dashboard-components";

import { ClassroomCeMasteryTable } from "./classroom.ce-mastery.table";

export function ClassroomCeSimulationAccordion({
  sim,
  index = 0,
  isExpanded,
  responsiveStyles,
  explored = true,
  hideExpandProperties = false,
}) {
  return (
    <Accordion
      className="sim-accordion"
      isOpen={isExpanded}
      disabled={!explored}
      hideExpandProperties={hideExpandProperties}
    >
      <div className="sim-accordion-header">
        <span className="sim-index">{index}</span>
        <AccordionHeader>
          <AccordionHeaderName>
            <span>{sim?.name}</span>
            {sim?.occupationName && <span>({sim.occupationName})</span>}
            <TextTooltip text={sim?.description}>
              <FontAwesomeIcon icon={"fa-regular fa-circle-info"} />
            </TextTooltip>
          </AccordionHeaderName>
        </AccordionHeader>
      </div>
      <AccordionBody>
        <ClassroomCeMasteryTable
          data={sim.explorers}
          responsiveStyles={responsiveStyles}
        ></ClassroomCeMasteryTable>
      </AccordionBody>
    </Accordion>
  );
}
