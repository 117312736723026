import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";

import {
  Badge,
  Link,
  OverflowText,
  PlaceholderProducts,
} from "@transfr-inc/dashboard-components";

import "./shortcut.scss";

export default ({
  title,
  subtitle,
  products,
  to,
  className,
  liveCount = 0,
}) => {
  return (
    <Link
      routerComponent={NavLink}
      className={clsx("shortcut", className)}
      to={to}
      underline={false}
    >
      <div className="content">
        <div className="description">
          <div className="classroom-name">
            <OverflowText className={"title"} text={title}>
              {title}
            </OverflowText>
            {liveCount > 0 && (
              <Badge className={"live-count"} value={`${liveCount} LIVE`} />
            )}
          </div>
          <div className="subtitle">{subtitle}</div>
        </div>
        <div className="placeholders">
          {products && (
            <PlaceholderProducts
              products={products.sort()}
            ></PlaceholderProducts>
          )}
        </div>
      </div>
      <div>
        <FontAwesomeIcon icon="fa-light fa-chevron-right" />
      </div>
    </Link>
  );
};
