import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionHeaderName,
  Badge,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { Button } from "@transfr-inc/dashboard-components/forms";
import {
  DataTable,
  Column,
  ColumnOptions,
  ColumnType,
  SortDirection,
  BasicCell,
} from "@transfr-inc/dashboard-components/data-table";
import { useContainerQuery } from "@transfr-inc/dashboard-components/container-query";

import container from "../../../container";
import { useApiRequest } from "../../../lib/http-client";
import SyncEventDetailsModal from "./sync-event-details.modal";
import IntegrationDate from "../list/components/integration-date";
import "./sync-history.scss";

const SyncHistoryItem = ({ syncRecord }) => {
  const { integrationService } = container;
  const { status, executionDateTime, message, results, jobExecutionId } =
    syncRecord;
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const { loading: downloadingCSV, sendRequest: exportSyncData } =
    useApiRequest(
      () => integrationService.getSyncDetails(jobExecutionId),
      false
    );

  const isFailed = status === "Failed";
  const isPending = status === "Pending";
  const isSuccessful = !isFailed && !isPending;

  const dayOfWeek = new Date(executionDateTime).toLocaleDateString("en-US", {
    weekday: "long",
    timeZone: "UTC",
  });

  const responsiveQueryForTable = {
    "small-panel": {
      maxWidth: 500,
    },
    "large-panel": {
      minWidth: 500,
    },
  };

  const [responsiveStylesForTable, accordionRef] = useContainerQuery(
    responsiveQueryForTable
  );

  const typeLabels = {
    student: "Trainees",
    teacher: "Instructors",
    classrooms: "Classrooms",
  };

  const eventLabels = {
    create: "created",
    update: "updated",
    delete: "deleted",
    error: "errors",
  };

  const labeledSyncRecord = results.map((record, i) => ({
    ...record,
    typeLabel: typeLabels[record.type],
  }));

  const eventDetailsCellFn = (eventType) => (value, rowData) => {
    return value > 0 ? (
      <button
        className="link-button"
        onClick={() =>
          setShowDetailsModal({
            eventType,
            resourceType: rowData.type,
            typeLabel: typeLabels[rowData.type],
            eventLabel: eventLabels[eventType],
          })
        }
      >
        {value}
      </button>
    ) : (
      <BasicCell value={value} />
    );
  };

  const columns = [
    new Column(
      "typeLabel",
      "data type",
      ColumnType.BASIC,
      new ColumnOptions({
        sortingEnabled: true,
      })
    ),
    new Column(
      "create",
      eventLabels.create,
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: eventDetailsCellFn("create"),
      })
    ),
    new Column(
      "update",
      eventLabels.update,
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: eventDetailsCellFn("update"),
      })
    ),
    new Column(
      "error",
      eventLabels.error,
      ColumnType.CUSTOM,
      new ColumnOptions({
        sortingEnabled: true,
        customFunction: (value) =>
          value > 0 ? (
            <div>
              <Badge
                className="error error-count"
                value={
                  <>
                    <FontAwesomeIcon
                      icon={["fa-solid", "triangle-exclamation"]}
                    />
                    {value}
                  </>
                }
              />
            </div>
          ) : (
            <BasicCell value={value} />
          ),
      })
    ),
  ];

  return (
    <div className={`sync-history-item ${status}`}>
      <Accordion isOpen={false} disabled={!isSuccessful} ref={accordionRef}>
        <AccordionHeader>
          <AccordionHeaderName>
            <div className="header-name-container">
              <div className="date">
                <Badge
                  value={dayOfWeek}
                  className={`badge ${isFailed ? "error" : ""}`}
                />
                <IntegrationDate date={executionDateTime} />
              </div>
              <div className="message">
                {isFailed && (
                  <FontAwesomeIcon
                    icon={["fa-solid", "triangle-exclamation"]}
                  />
                )}
                {(isFailed || isPending) && message}
              </div>
            </div>
          </AccordionHeaderName>
        </AccordionHeader>
        {isSuccessful && (
          <AccordionBody>
            <div className="csv-download-container">
              <Button
                size="small"
                icon={["fa-light", "file-export"]}
                onClick={exportSyncData}
                disabled={downloadingCSV}
              >
                Export Data (.csv)
              </Button>
              <span>Download .csv to get sync details.</span>
            </div>
            <div className="data-table-container">
              <DataTable
                className={clsx(
                  "sync-history-item__results-table",
                  responsiveStylesForTable
                )}
                columns={columns}
                data={labeledSyncRecord ?? []}
                keyFields={["type"]}
                defaultSortPropName={"type"}
                defaultSortDirection={SortDirection.DESCENDING}
                responsiveStyles={responsiveStylesForTable}
              />
            </div>
          </AccordionBody>
        )}
      </Accordion>
      {showDetailsModal && (
        <SyncEventDetailsModal
          open={!!showDetailsModal}
          onClose={() => setShowDetailsModal(false)}
          resourceType={showDetailsModal.resourceType}
          eventType={showDetailsModal.eventType}
          typeLabel={showDetailsModal.typeLabel}
          eventLabel={showDetailsModal.eventLabel}
          jobExecutionId={jobExecutionId}
          executionDateTime={executionDateTime}
        />
      )}
      <div className="notification-loader-container">
        {downloadingCSV && (
          <Notification
            type={NotificationType.info}
            closable
            icon={["fa-solid", "spinner"]}
            iconConfig={{ spin: true }}
          >
            We’re preparing a download for sync history details. Please wait...
          </Notification>
        )}
      </div>
    </div>
  );
};

export default SyncHistoryItem;
