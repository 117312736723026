import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useHistory } from "react-router-dom";

import {
  Link,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import { Button, Input } from "@transfr-inc/dashboard-components/forms";
import {
  LegalFooter,
  SideImageLayout,
} from "@transfr-inc/dashboard-components/layouts";

import { useQueryString } from "../../lib/hooks";
import { RootPath } from "../../lib/routes";
import { PrivacyPolicy, Terms } from "../../models/links";
import { MaintenanceWarningMessage } from "./components/maintenance.notification";
import { SelfSignup } from "./self-signup";

import personA from "../../../public/images/vr-person-a.jpg";
import personB from "../../../public/images/vr-person-b.jpg";

function getImage(value) {
  return value % 2 ? personA : personB;
}

export default function Login() {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const imageRef = useRef(Math.floor(Math.random() * 100));
  const queryParams = useQueryString();
  const notificationRef = useRef();

  const { register, handleSubmit, formState } = useForm({
    criteriaMode: "all",
    mode: "onBlur",
  });

  const actions = useStoreActions((store) => store.account);
  const state = useStoreState((store) => store.account);
  const { maintenanceWarningEnabled, technicalSupportEmail, redirect } =
    useStoreState((store) => store.app);

  const [notification, setNotification] = useState();
  const [warning, setWarning] = useState(maintenanceWarningEnabled);

  const fromResetPassword = history.location.state?.passwordResetSuccess;
  const fromCreateAccount = useStoreState(
    (store) => store.createAccount.saveState.success
  );
  const fromProProfsLogin = history.location.state?.fromProProfsLogin;
  const fromProProfsLoginUrl = history.location.state?.fromProProfsLoginUrl;
  const sourceDomain = useStoreState(
    (store) => store.account.proProfsSettings?.ssoSourceUrl?.hostname
  );
  const fromAppLogoutRequested = useStoreState(
    (store) => store.account.proProfsSettings?.appLogoutRequested
  );
  const proprofsSiteUrl = process.env.PROPROFS_SITE_URL;

  useEffect(() => {
    console.log(technicalSupportEmail);
    if (state.error) {
      if (queryParams.qr_signup_token) {
        if (state.error.status === 401) {
          setNotification({
            type: NotificationType.error,
            message: "Invalid authorization token.",
            id: "signup-error",
          });
        } else {
          setNotification({
            type: NotificationType.error,
            message: "An authentication error has occured.",
            id: "signup-error",
          });
        }
      } else {
        if (state.error.status === 403) {
          setNotification({
            type: NotificationType.error,
            message: (
              <div>
                Organization account expired.{" "}
                <u>
                  {" "}
                  <a href="mailto:technicalsupport@transfrvr.com">
                    Contact technical support
                  </a>{" "}
                </u>
                to resolve.
              </div>
            ),
            id: "login-error",
          });
        } else {
          setNotification({
            type: NotificationType.error,
            message: "Email or password incorrect",
            id: "login-error",
          });
        }
      }
      setLoading(); // Now setting loading false when any error is caught to avoid WOD
    }
  }, [state.error]);

  useEffect(() => {
    if (queryParams.it) {
      actions.impersonate(queryParams.it);
    } else if (queryParams.qr_signup_token) {
      actions.selfSignup(queryParams.qr_signup_token);
    } else {
      if (queryParams.upload_id) {
        actions.setBulkUploadId(queryParams.upload_id);
      }
      setLoading();
      if (fromResetPassword) {
        setNotification({
          type: NotificationType.success,
          message: "Password reset complete!",
          icon: "fa-lock",
        });
      } else if (fromCreateAccount) {
        setNotification({
          type: NotificationType.success,
          message: "Account setup complete!",
          icon: "fa-user-check",
        });
      } else if (
        !fromAppLogoutRequested &&
        (fromProProfsLogin || fromProProfsLoginUrl)
      ) {
        setNotification({
          type: NotificationType.success,
          message: proprofsSiteUrl.includes(sourceDomain)
            ? "Please login to access Support Center."
            : "Please login to access Documentation.",
          icon: "fa-lock",
        });
      }
    }
  }, []);

  // think of this as onPageLoad if the deps array is []
  // think of this as every render if the deps array is undefined
  // think of this as $watch `thing` if the deps array is `thing`
  useEffect(() => {
    if (state.isLoggedIn) {
      if (fromProProfsLogin && !fromAppLogoutRequested) {
        const urlParams = history.location.state?.urlParams
          ? `?${encodeURIComponent(history.location.state?.urlParams)}`
          : "";
        history.replace("/proprofs-auth" + urlParams);
      } else {
        const pathToRedirect = redirect ? "/redirect" : "";
        actions.clearLogOutRequested();
        history.replace(`${RootPath}${pathToRedirect}`);
      }
    }
  }, [state.isLoggedIn]);

  useEffect(() => {
    if (state.selfSignupData) {
      setLoading();
    }
  }, [state.selfSignupData]);

  if (!loading && state.selfSignupData) {
    return <SelfSignup />;
  }

  return !loading && !state.impersonating ? (
    <SideImageLayout
      title={["Welcome", "Back"]}
      image={getImage(imageRef.current)}
      footer={
        <LegalFooter terms={Terms.url} privacyPolicy={PrivacyPolicy.url} />
      }
    >
      {warning && <MaintenanceWarningMessage onClose={() => setWarning()} />}
      {notification && (
        <Notification
          type={notification.type}
          icon={notification.icon}
          onClose={() => setNotification()}
        >
          {notification.message}
        </Notification>
      )}
      <form
        className="account-form"
        onSubmit={handleSubmit(actions.login)}
        noValidate={true}
      >
        <div className="inputs">
          <Input
            label="Email or Username"
            type="text"
            required
            notification={notificationRef}
            errors={formState.errors.username}
            {...register("username", {
              required: true,
            })}
          />

          <Input
            type="password"
            label="Password"
            required
            notification={notificationRef}
            errors={formState.errors.password}
            {...register("password", { required: true })}
          />
        </div>
        <Button
          label="Sign In"
          primary={true}
          type="submit"
          disabled={state.resolving}
          loader={state.resolving}
        />
        <div className="form-options">
          <Link routerComponent={NavLink} underline to={"/forgot-password"}>
            Forgot Password
          </Link>
        </div>
      </form>
    </SideImageLayout>
  ) : (
    <div></div>
  );
}
