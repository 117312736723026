import React, { useEffect, useState } from "react";

import {
  Link,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";
import {
  Button,
  Filter,
  SearchBox,
  useFilter,
  useSearch,
} from "@transfr-inc/dashboard-components/forms";
import { useUnloadWarning } from "@transfr-inc/dashboard-components/hooks";
import { formatTimestap } from "@transfr-inc/dashboard-components/utils";

import container from "../../../../container";
import { useApiRequest } from "../../../../lib/http-client";

import { getSimsNames, searchSimsByName } from "./classroom.modules.utils";
import { ClassroomSimulationsFilter } from "./classroom.ts.databoxes";

import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TransfrFeedbackForm } from "../../../../models";
import { AllModulesOption } from "../views/classroom.progress.ts.tab";

import "./classroom.insights.scss";

export function ClassroomInsights({
  classroom,
  masteryStats,
  onStatusSelected,
  onModuleSelected,
  onSimNameSelected,
  disabled,
  data,
  filterSelected,
  searchTerm,
  onExpandCollapseAllModules,
  isAllModulesExpanded,
  currentModuleId,
}) {
  const [filters, setFilters] = useState([]);
  const [modules, setModules] = useState(data);
  const [simsNames, setSimsNames] = useState();

  const { classroomId, name } = classroom;
  const { insightsService } = container;

  const exportInsightsRequest = () => {
    const { clientCode: orgCode } = classroom;
    const fileName = filterSelected
      ? `${name} - ${filterSelected} - ${formatTimestap()}`
      : `${name} - ${formatTimestap()}`;
    return insightsService.exportClassroomData(
      classroomId,
      orgCode,
      fileName,
      currentModuleId
    );
  };

  const { loading: downloadingFile, sendRequest: exportInsights } =
    useApiRequest(exportInsightsRequest, false);

  useUnloadWarning({ trigger: downloadingFile });

  const filterByModule = useFilter({
    data: modules,
    filterKey: "displayName",
  });

  const simsNamesSearch = useSearch({
    data: modules,
    queryFn: searchSimsByName,
  });

  const onFilterStatusSelected = (optionSelected) => {
    const filterSelected = optionSelected === filters[0] ? "" : optionSelected;
    const results = filterByModule.queryData(filterSelected);
    onModuleSelected?.(results);
  };

  const onSimsNameTermChange = (term) => {
    !term && onSimNameSelected?.();
  };

  const onSimsNameSelected = (term) => {
    const results = simsNamesSearch.queryData(term);
    const simulation = results[0]?.simulations[0] || {};
    onSimNameSelected?.(simulation);
  };

  const updateFilterOptions = (data) => {
    const modulesNames = data.map((d) => d.displayName);
    setFilters([AllModulesOption, ...modulesNames]);
    filterByModule.updateData(data);
  };

  useEffect(() => {
    if (data) {
      setModules(data);
      setSimsNames(getSimsNames(data));
      simsNamesSearch.updateData(data);
      updateFilterOptions(data);
    }
  }, [data]);

  return (
    <div className="classroom-insights-section">
      <div className="header">
        <div className="right-section">
          <h2>Simulations</h2>
          <SearchBox
            className="sims-search"
            placeholder="Search"
            data={simsNames}
            disabled={!modules}
            onSearchTermChange={onSimsNameTermChange}
            onResultSelected={onSimsNameSelected}
            searchTerm={searchTerm}
          ></SearchBox>
          {/* Temporarily removed due render issues with tables */}
          {/* TODO: Improve DataTable component rendering as possible solution */}
          {/* <Button
            icon={
              isAllModulesExpanded
                ? ["fa-light", "fa-arrows-to-line"]
                : ["fa-light", "fa-arrows-from-line"]
            }
            onClick={() => onExpandCollapseAllModules()}
            tooltip={isAllModulesExpanded ? "Collapse All" : "Expand All"}
          ></Button> */}
        </div>
        <div className="left-section">
          <Filter
            className="module-filter"
            placeholder="Filter by Module"
            data={filters}
            onFilterSelected={onFilterStatusSelected}
            disabled={!modules}
            clearValue={AllModulesOption}
            selectedOption={filterSelected}
          ></Filter>
          <Button size="small">
            <Link href={TransfrFeedbackForm.url} underline={false}>
              Feedback <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Link>
          </Button>
          <Button
            primary
            size="small"
            icon={["fa-light", "file-export"]}
            onClick={exportInsights}
            disabled={downloadingFile || disabled}
          >
            Export Data (.csv)
          </Button>
        </div>
      </div>
      <div className="insights-databoxes">
        <ClassroomSimulationsFilter
          masteryStats={masteryStats}
          onStatusSelected={onStatusSelected}
          disabled={disabled}
        ></ClassroomSimulationsFilter>
      </div>
      <div className="notification-loader-container">
        {downloadingFile && (
          <Notification
            type={NotificationType.info}
            closable
            icon={["fa-solid", "spinner"]}
            iconConfig={{ spin: true }}
          >
            We’re preparing a download of <strong>{`${name}'s`}</strong>{" "}
            insights. Please wait...
          </Notification>
        )}
      </div>
    </div>
  );
}
